import React, { useState } from "react";
import css from "./index.module.css";
import { getCFURL, getImageName } from "utils/helpers";
import webShare from "utils/helpers/web-share";
import { allowShareDownload } from "views/download/helpers";
import LinkShareModal from "views/components/modals/LinkShareModal";
import SuspenseLoader from "views/components/loader/SuspenseLoader";
import { ssProxy } from "utils/helpers/localstorage";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProductSalesIcons = React.lazy(() =>
  import("views/gallary/gallaryPage/components/ProductSalesIcons")
);
const CartAddWarn = React.lazy(() => import("./CartAddWarn"));

const AnonymousGalleryToolbar = ({
  image,
  folderId,
  imgIndex,
  myPhotosCount,
  toggleFavorite,
  handleDownload,
  showFavBtn,
}) => {
  const navigate = useNavigate();
  const [cartWarn, setCartWarn] = useState(false);
  const [socialShareModal, setSocialShareModal] = useState(false);

  const {
    anonymousUser: { webSettings, group },
  } = useSelector((state) => state.anonymous);

  const { totalCount } = useSelector((state) => state.folderImages);

  const allowDownload = webSettings?.download?.allowDownload;

  const handleBackbtn = () => {
    let queryString = window.location.search;
    queryString = queryString.split("&imageId=");
    navigate(queryString[0]);
  };

  const fGroupCode = ssProxy.getItem("fGroupCode");
  const adminToken = ssProxy.getItem("adminToken");

  return (
    <>
      <div>
        <img
          className="navigate_icons gallary-modal-back-button"
          onClick={() => {
            handleBackbtn();
          }}
          src="/assets/icons/arrow.svg"
          alt=""
        />
      </div>

      <div className="image_count">
        {folderId === "my-photos"
          ? `${imgIndex + 1}/${myPhotosCount}`
          : `${imgIndex + 1}/${totalCount}`}
      </div>

      <div className="image_name">{getImageName(image.url)}</div>

      <div className={css["toolbar-container"]}>
        <ul className={css.toolbar}>
          {group?.isForProductSale && (
            <li onClick={() => setCartWarn(true)}>
              <img src="/assets/icons/add-to-cart.png" title="Add to Cart" />
            </li>
          )}

          <li onClick={() => handleDownload({ url: image.url })}>
            <img src="/assets/icons/download.png" alt="Download" />
          </li>

          <li
            onClick={() => {
              const url = image.url;

              allowShareDownload(
                false,
                allowDownload,
                async () => {
                  await webShare(url, url, () => {
                    setSocialShareModal(getCFURL({ url }));
                  });
                },
                "Share",
                group.isForProductSale,
                false // anon user can't purchase
              );
            }}
          >
            <img src="/assets/icons/share.png" alt="Share" />
          </li>

          {showFavBtn && (
            <li
              onClick={() => {
                const { _id, imageId, isFavorite } = image;

                toggleFavorite(imageId || _id, imgIndex, isFavorite, true);
              }}
            >
              <img
                alt="Favorite"
                {...(image.isFavorite
                  ? {
                      src: "/assets/icons/favorite-filled.png",
                      title: "Remove from favorites",
                    }
                  : {
                      src: "/assets/icons/favorite.png",
                      title: "Add to favorites",
                    })}
              />
            </li>
          )}
        </ul>
      </div>
      {socialShareModal && (
        <LinkShareModal
          show={socialShareModal}
          onHide={() => setSocialShareModal("")}
          title=""
          url={socialShareModal}
        />
      )}

      <React.Suspense fallback={<SuspenseLoader />}>
        {group?.isForProductSale && (
          <>
            <ProductSalesIcons
              src="fullsize"
              uploadedByCurrentUser={false}
              authCode={false}
            />

            {cartWarn && (
              <CartAddWarn
                show={true}
                onCancel={() => setCartWarn(false)}
                groupCode={group?.groupCode}
                fGroupCode={fGroupCode}
                adminToken={adminToken}
              />
            )}
          </>
        )}
      </React.Suspense>
    </>
  );
};

export default AnonymousGalleryToolbar;
